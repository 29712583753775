import { EWorkRecordNotified, EWorkRecordStatus } from "../enums/workRecord-enums";
import { IWorkRecord } from "../interfaces/work-record.interface";

export class WorkRecord implements IWorkRecord {
  private _id: string;
  private _uuid: string;
  private _editorId: string;
  private _startTime: Date;
  private _endTime: Date | null | undefined;
  private _comment: string | null | undefined;
  private _commentEditor: string | null | undefined;
  private _notified: EWorkRecordNotified;
  private _status: EWorkRecordStatus;
  private _editor:
    | {
        firstName: string;
        lastName: string;
      }
    | null
    | undefined;

  constructor(workRecord: IWorkRecord) {
    this._id = workRecord.id;
    this._uuid = workRecord.uuid;
    this._editorId = workRecord.editorId;
    this._startTime = new Date(workRecord.startTime);
    if (workRecord.endTime) {
      this._endTime = new Date(workRecord.endTime!);
    }
    this._comment = workRecord.comment;
    this._commentEditor = workRecord.commentEditor;
    this._notified = workRecord.notified;
    this._status = workRecord.status;
    this._editor = workRecord.editor;
  }

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(uuid: string) {
    this._uuid = uuid;
  }

  get editorId(): string {
    return this._editorId;
  }

  set editorId(id: string) {
    this._editorId = id;
  }

  get startTime(): Date {
    return this._startTime;
  }

  set startTime(startTime: Date) {
    this._startTime = startTime;
  }

  get endTime(): Date | null | undefined {
    return this._endTime;
  }

  set endTime(endTime: Date | null | undefined) {
    this._endTime = endTime;
  }

  get comment(): string | null | undefined {
    return this._comment;
  }

  set comment(comment: string | null | undefined) {
    this._comment = comment;
  }

  get commentEditor(): string | null | undefined {
    return this._commentEditor;
  }

  set commentEditor(comment: string | null | undefined) {
    this._commentEditor = comment;
  }

  get notified(): EWorkRecordNotified {
    return this._notified;
  }

  set notified(notify: EWorkRecordNotified) {
    this._notified = notify;
  }

  get status(): EWorkRecordStatus {
    return this._status;
  }

  set status(status: EWorkRecordStatus) {
    this._status = status;
  }

  get editor():
    | {
        firstName: string;
        lastName: string;
      }
    | null
    | undefined {
    return this._editor;
  }

  set editor(editor: { firstName: string; lastName: string } | null | undefined) {
    this._editor = editor;
  }

  getEditorName(): string {
    if (!this._editor) {
      return "";
    }

    return this._editor.firstName + " " + this._editor.lastName;
  }

  getWorkedTime(): number | undefined {
    if (!this._endTime) {
      return undefined;
    }

    return this._endTime.getTime() - this._startTime.getTime();
  }
}
