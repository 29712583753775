import { EAbsencesType } from "../enums/absences.enum";
import { ENotificationAbsenceType } from "../enums/notification.enum";
import { INotificationAbsence } from "../interfaces/notification.interface";
import { Notification } from "./notification.model";

export class NotificationAbsence extends Notification implements INotificationAbsence {

    private _absenceId: string;
    private _absenceNotificationType: ENotificationAbsenceType;
    private _absenceType: EAbsencesType;
    private _startDate: Date;

    private _endDate: Date;

    constructor(notification: INotificationAbsence) {
        super(notification)
        this._absenceId = notification.absenceId;
        this._absenceNotificationType = notification.absenceNotificationType;
        this._absenceType = notification.absenceType;
        this._startDate = notification.startDate;
        this._endDate = notification.endDate;
    }

    get absenceId(): string {
        return this._absenceId;
    }
    set absenceId(value: string) {
        this._absenceId = value;
    }

    get absenceNotificationType(): ENotificationAbsenceType {
        return this._absenceNotificationType;
    }
    set absenceNotificationType(value: ENotificationAbsenceType) {
        this._absenceNotificationType = value;
    }

    get absenceType(): EAbsencesType {
        return this._absenceType;
    }
    set absenceType(value: EAbsencesType) {
        this._absenceType = value;
    }

    get startDate(): Date {
        return this._startDate;
    }

    set startDate(date: Date) {
        this._startDate = date;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(date: Date) {
        this._endDate = date;
    }

}