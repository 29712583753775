<ion-label color="medium">
  <ion-text color="app-success"><fa-icon icon="umbrella-beach"></fa-icon></ion-text>
  {{
  "PAGES.ABSENT_RECORD.TITLE.VACATION" | translate
  }}</ion-label>
<div class="progress-wrapper">
  <div class="progress-bar" #progressBar>
    <div class="approved" #progressApproved>
      <div class="approved-count" #approvedCount>
        {{ approvedVacationDays }}
      </div>
    </div>
    <div class="open" #progressOpen>
      <div class="open-count" #openCount>{{ openVacationDays }}</div>
    </div>
  </div>
  <ng-container *ngIf="carryOverVacationDays > 0">
    <ion-text class="total" color="medium">(<span class="carry-over">{{ carryOverVacationDays }}</span>)</ion-text>
    <ion-text class="total" color="medium">{{ totalVacationDays }}</ion-text>
  </ng-container>
  <ng-container *ngIf="carryOverVacationDays == 0">
    <ion-text class="total" color="medium">{{ totalVacationDays }}</ion-text>
  </ng-container>
</div>