import { createFeature, createReducer, on } from "@ngrx/store";
import { ITimeState } from "./interfaces/time.interface";
import { timeActions } from "./actions";

const initialState: ITimeState = {
  systemTime: new Date(),
  customTimeDiff: 0,
};

const systemTimeFeature = createFeature({
  name: "systemTime",
  reducer: createReducer(
    initialState,
    on(timeActions.fetchTimeSuccess, (state, action) => ({
      ...state,
      systemTime: action.dates.systemTime,
      customTimeDiff: action.dates.customTimeDiff,
    })),
    on(timeActions.setTime, (state, action) => ({
      ...state,
      systemTime: action.date,
    })),
    on(timeActions.setCustomTimeDiff, (state, action) => ({
      ...state,
      customTimeDiff: action.timeDiff,
    })),
    on(timeActions.resetCustomTime, (state) => ({
      ...state,
      customTimeDiff: 0,
    }))
  ),
});

export const { name: systemTimeFeatureKey, reducer: systemTimeReducer, selectSystemTime, selectCustomTimeDiff } = systemTimeFeature;
