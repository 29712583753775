import { ENotificationStatus } from "../enums/notification.enum";
import { INotification } from "../interfaces/notification.interface";

export class Notification implements INotification {

    private _id: string ;
    private _uuid: string;
    private _status: ENotificationStatus;
    private _recipient: string;
    private _author: { firstName: string, lastName: string};
    private _created: Date | undefined;

    constructor(notification: INotification) {
        this._id = notification.id;
        this._uuid = notification.uuid;
        this._status = notification.status;
        this._recipient = notification.recipient;
        this._author = notification.author;
        this._created = notification.created;
    }

    get id(): string {
        return this._id;
    }
    set id(value: string) {
        this._id = value;
    }

    get uuid(): string {
        return this._uuid;
    }
    set uuid(value: string) {
        this._uuid = value;
    }

    get status(): ENotificationStatus {
        return this._status;
    }
    set status(value: ENotificationStatus) {
        this._status = value;
    }

    get recipient(): string  {
        return this._recipient;
    }
    set recipient(value: string) {
        this._recipient = value;
    }

    get author(): { firstName: string, lastName: string } {
        return this._author;
    }
    set author(value: {firstName: string, lastName: string} ) {
        this._author = { firstName: value.firstName, lastName: value.lastName };
    }

    get created(): Date | undefined {
        return this._created;
    }
    set created(value: Date | undefined) {
        this._created = value;
    }

 }