import { IHolidayTemplate } from "src/app/core/interfaces/holiday-template.interface";

// Source https://www.schulferien.org/Kalender_mit_Ferien/kalender_2023_ferien_Nordrhein_Westfalen.html
export const holidays = [
  {
    year: 2020,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 10 } },
      { name: "Ostermontag", date: { month: 3, day: 13 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 21 } },
      { name: "Pfingstmontag", date: { month: 5, day: 1 } },
      { name: "Fronleichnam", date: { month: 5, day: 11 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2021,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 2 } },
      { name: "Ostermontag", date: { month: 3, day: 5 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 13 } },
      { name: "Pfingstmontag", date: { month: 4, day: 24 } },
      { name: "Fronleichnam", date: { month: 5, day: 3 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2022,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 15 } },
      { name: "Ostermontag", date: { month: 3, day: 18 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 26 } },
      { name: "Pfingstmontag", date: { month: 5, day: 6 } },
      { name: "Fronleichnam", date: { month: 5, day: 16 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2023,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 7 } },
      { name: "Ostermontag", date: { month: 3, day: 10 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 18 } },
      { name: "Pfingstmontag", date: { month: 4, day: 29 } },
      { name: "Fronleichnam", date: { month: 5, day: 8 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2024,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 2, day: 29 } },
      { name: "Ostermontag", date: { month: 3, day: 1 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 9 } },
      { name: "Pfingstmontag", date: { month: 4, day: 20 } },
      { name: "Fronleichnam", date: { month: 4, day: 30 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2025,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 18 } },
      { name: "Ostermontag", date: { month: 3, day: 21 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 29 } },
      { name: "Pfingstmontag", date: { month: 5, day: 9 } },
      { name: "Fronleichnam", date: { month: 5, day: 19 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2026,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 3 } },
      { name: "Ostermontag", date: { month: 3, day: 6 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 14 } },
      { name: "Pfingstmontag", date: { month: 4, day: 25 } },
      { name: "Fronleichnam", date: { month: 5, day: 4 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2027,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 2, day: 26 } },
      { name: "Ostermontag", date: { month: 2, day: 29 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 6 } },
      { name: "Pfingstmontag", date: { month: 4, day: 17 } },
      { name: "Fronleichnam", date: { month: 4, day: 27 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2028,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 14 } },
      { name: "Ostermontag", date: { month: 3, day: 17 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 25 } },
      { name: "Pfingstmontag", date: { month: 5, day: 5 } },
      { name: "Fronleichnam", date: { month: 5, day: 15 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2029,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 2, day: 30 } },
      { name: "Ostermontag", date: { month: 3, day: 2 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 10 } },
      { name: "Pfingstmontag", date: { month: 4, day: 21 } },
      { name: "Fronleichnam", date: { month: 4, day: 31 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2030,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 19 } },
      { name: "Ostermontag", date: { month: 3, day: 22 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 30 } },
      { name: "Pfingstmontag", date: { month: 5, day: 10 } },
      { name: "Fronleichnam", date: { month: 5, day: 20 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
  {
    year: 2031,
    data: [
      { name: "Neujahr", date: { month: 0, day: 1 } },
      { name: "Karfreitag", date: { month: 3, day: 11 } },
      { name: "Ostermontag", date: { month: 3, day: 14 } },
      { name: "Tag der Arbeit", date: { month: 4, day: 1 } },
      { name: "Christi Himmelfahrt", date: { month: 4, day: 22 } },
      { name: "Pfingstmontag", date: { month: 5, day: 2 } },
      { name: "Fronleichnam", date: { month: 5, day: 12 } },
      { name: "Tag der Deutschen Einheit", date: { month: 9, day: 3 } },
      { name: "Allerheiligen", date: { month: 10, day: 1 } },
      { name: "1. Weihnachtsfeiertag", date: { month: 11, day: 25 } },
      { name: "2. Weihnachtsfeiertag", date: { month: 11, day: 26 } },
    ],
  },
];
