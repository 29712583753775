import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Store } from "@ngrx/store";
import { EAbsenceNotified, EAbsenceStatus } from "src/app/core/enums/absences.enum";
import { IAbsence } from "src/app/core/interfaces/absence.interface";
import { Absence } from "src/app/core/models/absence.model";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { IonList, NavController } from "@ionic/angular";
import * as AbsenceActions from "src/app/core/store/absences/absences.actions";
import { NGXLogger } from "ngx-logger";
import { Accounts } from "src/app/core/interfaces/account.types";
import { TranslateService } from "@ngx-translate/core";
import { EmployeeService } from "../../services/employee.service";

@Component({
  selector: "app-open-absences",
  templateUrl: "./open-absences.component.html",
  styleUrls: ["./open-absences.component.scss"],
})
export class OpenAbsencesComponent implements OnInit, OnChanges {
  @Input() absences: Absence[] = [];
  @Input() account!: Accounts | undefined;
  private ionSlideCloseTimeout: any;

  constructor(
    private logger: NGXLogger,
    private store: Store<IAppState>,
    private navCtrl: NavController,
    private employeeService: EmployeeService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.logger.info("OnInit: Initialize open absences component");
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
    }
  }

  getIconColor(absence: Absence): string {
    var title = "";

    switch (absence.type) {
      case 0:
        title = "app-success";
        break;
      case 1:
        title = "warning";
        break;
      case 2:
        title = "app-primary";
        break;
      case 3:
        title = "app-danger";
        break;
    }

    return title;
  }

  getIconName(absence: Absence): IconProp {
    var icon!: IconProp;

    switch (absence.type) {
      case 0:
        icon = "umbrella-beach";
        break;
      case 1:
        icon = "business-time";
        break;
      case 2:
        icon = "award";
        break;
      case 3:
        icon = "briefcase-medical";
        break;
    }

    return icon;
  }

  getStatusColor(absence: Absence) {
    var color!: string;

    switch (absence.status) {
      case 0:
        color = "medium";
        break;
      case 1:
        color = "app-success";
        break;
      case 2:
        color = "danger";
        break;
    }

    return color;
  }

  swipeHandler(event: any, absence: Absence) {
    switch (event.detail.side) {
      case "start":
        this.approveAbsence(absence);
        break;
      case "end":
        this.openAbsence(absence);
        break;
    }
  }

  getDurationCount(absence: Absence) {
    const count = this.employeeService.getAbsenceDurationCount(absence.startDate, absence.endDate);

    if (count == 1) {
      return count + " " + this.translateService.instant("COMPONENTS.OPEN_ABSENCES.DAY");
    }

    return count + " " + this.translateService.instant("COMPONENTS.OPEN_ABSENCES.DAYS");
  }

  approveAbsence(absence: Absence) {
    this.logger.info(`Click: Approve absence with id "${absence.id}"`);
    const absenceInterface: IAbsence = {
      id: absence.id,
      uuid: absence.uuid,
      editorId: this.account?.userId!,
      startDate: absence.startDate,
      endDate: absence.endDate,
      comment: absence.comment ? absence.comment : "",
      commentEditor: absence.commentEditor ? absence.commentEditor : "",
      type: absence.type,
      notified: EAbsenceNotified.NOT_NOTIFIED,
      status: EAbsenceStatus.APPROVED,
      employee: absence.employee,
      editor: absence.editor,
    };
    let tmpAbsence: Absence = new Absence(absenceInterface);
    this.store.dispatch(new AbsenceActions.UpdateAbsenceStatus({ absence: tmpAbsence, navigate: false }));
  }

  openAbsence(absence: Absence) {
    this.logger.info(`Click: Edit absence with id "${absence.id}"`);
    this.navCtrl.navigateForward(["/app/zeitkonto/abwesenheiten/edit", absence.id]);
  }

  getAbsenceDateRange(absence: Absence): string {
    return this.employeeService.getAbsenceDateRange(absence);
  }

  getAbsenceType(absence: Absence): string {
    return this.employeeService.getAbsenceType(absence.type);
  }

  itemDragged(list: IonList) {
    clearTimeout(this.ionSlideCloseTimeout);
    this.ionSlideCloseTimeout = setTimeout(() => {
      list.closeSlidingItems();
    }, 5000);
  }
}
