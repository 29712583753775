import { Action } from "@ngrx/store";
import { EMessageType } from "../../enums/message-type.enum";
import { HttpErrorResponse } from "@angular/common/http";

export const MESSAGE = '[UI] New Message';
export const ERROR = '[UI] Error';
export const ERROR_MESSAGE = '[UI] Error message';
export const START_LOADING = '[UI] Start Loading';
export const STOP_LOADING = '[UI] Stop Loading';
export const RESET_MESSAGE = '[UI] Reset Error';
export const SHOW_SPLASH = '[UI] Show splash screen';
export const STOP_SPLASH = '[UI] Stop splash screen';
export const HIDE_SPLASH = '[UI] Hide splash screen';

export class Message implements Action {
    readonly type = MESSAGE;
    constructor(public payload: { message: string, type: EMessageType }) {};
}

export class Error implements Action {
    readonly type = ERROR;
    constructor(public payload: {error: any, internal: boolean}) { };
}

export class ErrorMessage implements Action {
    readonly type = ERROR_MESSAGE;
    constructor(public payload: {message: string, supportFile: boolean, internal: boolean}) { };
}

export class StartLoading implements Action {
    readonly type = START_LOADING;
}

export class StopLoading implements Action {
    readonly type = STOP_LOADING;
}

export class ResetMessage implements Action {
    readonly type = RESET_MESSAGE;
}

export class ShowSplash implements Action {
    readonly type = SHOW_SPLASH;
}

export class StopSplash implements Action {
    readonly type = STOP_SPLASH;
}

export class HideSplash implements Action {
    readonly type = HIDE_SPLASH;
}

export type UIActions = Message | Error | ErrorMessage | StartLoading | StopLoading | ResetMessage | ShowSplash | StopSplash | HideSplash