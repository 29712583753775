import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { EmployeePresenceTypes } from "src/app/features/overview/components/employee-presence/models/employee-presence-status.model";
import { Employee } from "../../models/employee.model";

export const usersActions = createActionGroup({
  source: "Users",
  events: {
    "Fetch employees": emptyProps,
    "Fetch Employees success": props<{ employees: Employee[] }>(),
    "Fetch Employees failure": emptyProps,
    "Set Employees sync time": props<{ payload: Date }>(),
    "Set employees states": props<{
      payload: EmployeePresenceTypes;
    }>(),
  },
});
