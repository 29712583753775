import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest } from "rxjs";
import { selectLogs, selectSettings } from "../reducer";
import { ISettings } from "../interfaces/settings.interfaces";
import { INGXLoggerMetadata } from "ngx-logger";
import { StorageService } from "src/app/shared/services/storage.service";
import { EStorageKey } from "src/app/core/enums/storage-key.enum";
import { Accounts } from "src/app/core/interfaces/account.types";
import { AuthState } from "../../auth/auth.reducer";
import { IAppState } from "../../app/app.reducer";
import { SharedService } from "src/app/shared/services/shared.service";
import { format } from "date-fns-tz";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
/**
 * Service responsible for managing application settings.
 */
export class SettingsService {
  private combinedElements$!: Observable<[any, any, AuthState]>;
  private _settings: ISettings | null = null;
  private _logs: INGXLoggerMetadata[] = [];
  private _account: Accounts | null = null;

  /**
   * Creates an instance of SettingsService.
   * @param store - The Redux store.
   * @param storage - The storage service.
   * @param http - The HTTP client.
   * @param sharedService - The shared service.
   */
  constructor(
    private store: Store<IAppState>,
    private storage: StorageService,
    private http: HttpClient,
    private sharedService: SharedService
  ) {
    this.combinedElements$ = combineLatest([
      this.store.select(selectSettings),
      this.store.select(selectLogs),
      this.store.select("account"),
    ]);

    this.combinedElements$.subscribe(([settings, logs, accountState]: [any, any, AuthState]) => {
      this._settings = settings;
      this._logs = logs;
      this._account = accountState.account ? accountState.account : null;
    });
  }

  /**
   * Saves the settings in the local storage.
   */
  saveSettingsInLocaleStore(): void {
    this.storage.set(EStorageKey.SETTINGS, this._settings);
  }

  /**
   * Saves the logs in the local storage.
   */
  saveLogsInLocaleStore(): void {
    this.storage.set(EStorageKey.LOGS, this._logs);
  }

  /**
   * Sends the logs to the server.
   * @param message - The user input message.
   * @returns An observable of the HTTP response.
   */
  sendLogsToServer(message: string): Observable<HttpResponse<any>> {
    const body = {
      system: {
        user: this._account ? this._account.fullName : "undefined",
        appVersion: environment.version + " Build " + environment.buildNumber,
        appDate: format(this.sharedService.serverTime, "yyyy-MM-dd HH:mm:ssXXX", { timeZone: "Europe/Berlin" }),
        id: this.sharedService.deviceID?.uuid || "undefined",
        name: this.sharedService.deviceInfo?.name || "undefined",
        isVirtual: this.sharedService.deviceInfo?.isVirtual?.toString() || "undefined",
        manufacturer: this.sharedService.deviceInfo?.manufacturer || "undefined",
        model: this.sharedService.deviceInfo?.model || "undefined",
        operatingSystem: this.sharedService.deviceInfo?.operatingSystem || "undefined",
        osVersion: this.sharedService.deviceInfo?.osVersion || "undefined",
        platform: this.sharedService.deviceInfo?.platform || "undefined",
        memUsed: this.sharedService.deviceInfo?.memUsed?.toString() || "undefined",
        webViewVersion: this.sharedService.deviceInfo?.webViewVersion || "undefined",
        realDiskFree: this.sharedService.deviceInfo?.realDiskFree?.toString() || "undefined",
        realDiskTotal: this.sharedService.deviceInfo?.realDiskTotal?.toString() || "undefined",
        batteryLevel: this.sharedService.deviceInfo?.batteryLevel?.toString() || "undefined",
        isCharging: this.sharedService.deviceBatteryInfo?.isCharging?.toString() || "undefined",
        systemLanguageCode: this.sharedService.deviceLanguageCode?.value || "undefined",
        appLanguageCode: this._settings ? this._settings.language : "undefined",
        comment: message || "undefined",
      },
      logs: this._logs,
    };

    return this.http.post<any>(environment.apiUrl + "logs", JSON.stringify(body), {
      headers: { "Content-Type": "application/json" },
      observe: "response",
    });
  }
}
