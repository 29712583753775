import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NGXLoggerWriterService, INGXLoggerMetadata, INGXLoggerConfig, NgxLoggerLevel } from "ngx-logger";
import { IAppState } from "../store/app/app.reducer";
import { format } from "date-fns-tz";
import { environment } from "src/environments/environment";
import { settingsActions } from "../store/settings/actions";

@Injectable()
export class LoggerWriterService extends NGXLoggerWriterService {
  constructor(private store: Store<IAppState>) {
    super(0);
  }

  /** Write the content sent to the log function to the console */
  public override writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    const date = new Date(metadata.timestamp!);
    let timestamp = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSX", { timeZone: "Europe/Berlin" });
    const log: INGXLoggerMetadata = {
      ...metadata,
      timestamp: timestamp,
      columnNumber: metadata.columnNumber == undefined ? metadata.columnNumber : 0,
    };
    this.store.dispatch(settingsActions.addNewLogMessage({ log: log }));

    if (environment.apiUrl.includes("localhost") || environment.apiUrl.includes("testing") || environment.apiUrl.includes("staging")) {
      console.log(log.message);
    }
  }
}
