export enum EWorkRecordNotified {
    NOT_NOTIFIED,
    NOTIFIED,
}


export enum EWorkRecordStatus {
    CREATED_BY_EMPLOYEE_AUTOMATICALLY,
    CREATED_BY_EMPLOYEE_MANUALLY,
    CREATED_BY_EDITOR,
    UPDATED_BY_EMPLOYEE,
    UPDATED_BY_EDITOR,
    DELETED_BY_EMPLOYEE,
    DELETED_BY_EDITOR,
}