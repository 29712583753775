import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { SharedService } from "src/app/shared/services/shared.service";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { IAppState } from "../app/app.reducer";
import * as NotificationActions from "./notifications.actions";
import * as UIActions from "src/app/core/store/ui/ui.actions";
import { NGXLogger } from "ngx-logger";
import { uiActions } from "../ui/actions";

@Injectable()
export class NotificationsEffects {
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.FETCH),
      tap(() => {
        this.store.dispatch(uiActions.fetchNotifications());
      }),
      switchMap((action: NotificationActions.Fetch) => {
        this.logger.info(`Fetching notifications from server`);
        return this.http.get<any>(environment.apiUrl + "users/" + action.payload + "/notifications").pipe(
          map((response: any) => {
            const NOTIFICATIONS = this.sharedService.createNotificationsFromResponse(response);
            return new NotificationActions.SetNotifications(NOTIFICATIONS);
          }),
          catchError((errorRes) => {
            this.store.dispatch(uiActions.failedFetching());
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  updateAbsenceNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.UPDATE_ABSENCE),
      tap((action: NotificationActions.UpdateAbsenceNotification) => {
        this.store.dispatch(uiActions.fetchNotifications());
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Updating absence notification with id ${action.payload.id}`);
      }),
      switchMap((action: NotificationActions.UpdateAbsenceNotification) => {
        const absenceNotification = action.payload;
        return this.http
          .patch<any>(
            environment.apiUrl + "notifications/absences/" + absenceNotification.id,
            {
              id: absenceNotification.id,
              status: absenceNotification.status,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              return new NotificationActions.Fetch(absenceNotification.recipient);
            }),
            catchError((errorRes) => {
              this.store.dispatch(uiActions.fetchNotifications());
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  deleteAbsenceNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.DELETE_ABSENCE),
      tap((action: NotificationActions.UpdateAbsenceNotification) => {
        this.store.dispatch(uiActions.fetchNotifications());
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Deleting absence notification with id ${action.payload.id}`);
      }),
      switchMap((action: NotificationActions.UpdateAbsenceNotification) => {
        const absenceNotification = action.payload;
        return this.http.delete<any>(environment.apiUrl + "notifications/absences/" + absenceNotification.id, { observe: "response" }).pipe(
          map(() => {
            return new NotificationActions.Fetch(absenceNotification.recipient);
          }),
          catchError((errorRes) => {
            this.store.dispatch(uiActions.fetchNotifications());
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  updateWorkRecordNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.UPDATE_WORKRECORD),
      tap((action: NotificationActions.UpdateWorkRecordNotification) => {
        this.store.dispatch(uiActions.fetchNotifications());
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Updating work record notification with id ${action.payload.id}`);
      }),
      switchMap((action: NotificationActions.UpdateWorkRecordNotification) => {
        const workRecordNotification = action.payload;
        return this.http
          .patch<any>(
            environment.apiUrl + "notifications/workrecords/" + workRecordNotification.id,
            {
              id: workRecordNotification.id,
              status: workRecordNotification.status,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              return new NotificationActions.Fetch(workRecordNotification.recipient);
            }),
            catchError((errorRes) => {
              this.store.dispatch(uiActions.fetchNotifications());
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  deleteWorkRecordNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.DELETE_WORKRECORD),
      tap((action: NotificationActions.UpdateWorkRecordNotification) => {
        this.store.dispatch(uiActions.fetchNotifications());
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Deleting work record notification with id ${action.payload.id}`);
      }),
      switchMap((action: NotificationActions.UpdateWorkRecordNotification) => {
        const workRecordNotification = action.payload;
        return this.http
          .delete<any>(environment.apiUrl + "notifications/workrecords/" + workRecordNotification.id, { observe: "response" })
          .pipe(
            map(() => {
              return new NotificationActions.Fetch(workRecordNotification.recipient);
            }),
            catchError((errorRes) => {
              this.store.dispatch(uiActions.fetchNotifications());
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  setNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.SET_NOTIFICATIONS),
      map((action: NotificationActions.SetNotifications) => {
        this.store.dispatch(uiActions.successFetchingNotifications());
        this.logger.info(`Set notification in store`);
        return new UIActions.StopLoading();
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private sharedService: SharedService,
    private store: Store<IAppState>,
    private logger: NGXLogger
  ) {}
}
