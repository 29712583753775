import { INotifications } from "../../interfaces/notification.interface";
import { NotificationAbsence } from "../../models/notification-absence.model";
import * as NotificationActions from "./notifications.actions";

export interface NotificationsState {
    notifications: INotifications,
}

const initialState: NotificationsState = {
    notifications: {
        absences: [],
        workRecords:  []
    }
}

export function notificationReducer(state = initialState, action: NotificationActions.NotificationActions): NotificationsState {
    switch (action.type) {
        case NotificationActions.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: {...action.payload}
            }
        default:
            return state;
    }
}