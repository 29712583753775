import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const uiActions = createActionGroup({
  source: "UI",
  events: {
    "Fetch system time": emptyProps,
    "Success fetching system time": emptyProps,
    "Fetch personal data": emptyProps,
    "Success fetching personal data": emptyProps,
    "Fetch open absences": emptyProps,
    "Success fetching open absences": emptyProps,
    "Fetch notifications": emptyProps,
    "Success fetching notifications": emptyProps,
    "Fetch employees": emptyProps,
    "Success fetching employees": emptyProps,
    "Fetch work records": emptyProps,
    "Success fetching work records": emptyProps,
    "Fetch absences": emptyProps,
    "Success fetching absences": emptyProps,
    "Failed fetching": emptyProps,
  },
});
