import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Admin } from "src/app/core/models/admin.model";
import { Board } from "src/app/core/models/board.model";
import { Employee } from "src/app/core/models/employee.model";
import { AuthState } from "src/app/core/store/auth/auth.reducer";
import { usersActions } from "src/app/core/store/users/actions";
import * as AbsenceActions from "src/app/core/store/absences/absences.actions";
import * as NotificationActions from "src/app/core/store/notifications/notifications.actions";
import * as WorkRecordActions from "src/app/core/store/workRecords/workRecords.actions";
import { Accounts } from "src/app/core/interfaces/account.types";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  constructor(private store: Store) {}

  public initStore(account: Accounts | undefined, alias: Accounts | undefined = undefined) {
    if ((account instanceof Board || account instanceof Admin) && !alias) {
      this.store.dispatch(new AbsenceActions.FetchOpen(false));
      this.store.dispatch(new NotificationActions.Fetch(account.userId));
      this.store.dispatch(usersActions.fetchEmployees());
    }

    if ((account instanceof Board || account instanceof Admin) && alias instanceof Employee) {
      this.store.dispatch(new AbsenceActions.Fetch({ id: alias.userId, navigate: false }));
      this.store.dispatch(new NotificationActions.Fetch(alias.userId));
      this.store.dispatch(new WorkRecordActions.Fetch({ id: alias.userId, navigate: false }));
    }

    if (account instanceof Employee) {
      this.store.dispatch(new AbsenceActions.Fetch({ id: account.userId, navigate: false }));
      this.store.dispatch(new NotificationActions.Fetch(account.userId));
      this.store.dispatch(new WorkRecordActions.Fetch({ id: account.userId, navigate: false }));
      this.store.dispatch(usersActions.fetchEmployees());
    }
  }
}
