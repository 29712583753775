import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CardWrapperComponent } from "./components/card-wrapper/card-wrapper.component";
import { IonicModule } from "@ionic/angular";
import { HoursOverviewComponent } from "./components/hours-overview/hours-overview.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HoursProgressComponent } from "./components/hours-progress/hours-progress.component";
import { Drivers } from "@ionic/storage";
import { IonicStorageModule } from "@ionic/storage-angular";
import { ReactiveFormsModule } from "@angular/forms";
import { OpenAbsencesComponent } from "./components/open-absences/open-absences.component";
import { VacationOverviewComponent } from "../features/staff/components/vacation-overview/vacation-overview.component";
import { NotificationOverviewComponent } from "./components/notification-overview/notification-overview.component";
import { TranslateModule } from "@ngx-translate/core";
import { TimeDiffSystemTimePipe } from "./pipes/time-diff-system-time.pipe";

@NgModule({
  declarations: [
    CardWrapperComponent,
    HoursOverviewComponent,
    VacationOverviewComponent,
    HoursProgressComponent,
    OpenAbsencesComponent,
    NotificationOverviewComponent,
    TimeDiffSystemTimePipe,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: "__neobit",
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
  ],
  exports: [
    CardWrapperComponent,
    HoursOverviewComponent,
    VacationOverviewComponent,
    HoursProgressComponent,
    OpenAbsencesComponent,
    NotificationOverviewComponent,
    FontAwesomeModule,
    IonicStorageModule,
    TimeDiffSystemTimePipe,
  ],
})
export class SharedModule {}
