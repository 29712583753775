import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { ISettings, ISettingsState } from "./interfaces/settings.interfaces";
import { settingsActions } from "./actions";

let settings: ISettings = {
  language: "de",
  collapseTeamCalendar: false,
  useCustomTime: false,
};

const initialState: ISettingsState = {
  settings: settings,
  logs: [],
};

/**
 * The settings feature object.
 */
const settingsFeature = createFeature({
  name: "settings",
  reducer: createReducer(
    initialState,
    on(settingsActions.fetchSettingsSuccess, (state, action) => ({
      ...state,
      settings: action.settings,
      logs: [],
    })),
    on(settingsActions.setLanguage, (state, action) => ({
      ...state,
      settings: { ...state.settings, language: action.language },
    })),
    on(settingsActions.toggleTeamCalendar, (state, action) => ({
      ...state,
      settings: { ...state.settings, collapseTeamCalendar: action.collapseTeamCalendar },
    })),
    on(settingsActions.useCustomTime, (state, action) => ({
      ...state,
      settings: { ...state.settings, useCustomTime: action.useCustomTime },
    })),
    on(settingsActions.fetchLogsSuccess, (state, action) => ({
      ...state,
      logs: action.logs,
    })),
    on(settingsActions.addNewLogMessage, (state, action) => {
      let updatedLogs = [...state.logs, action.log];
      if (updatedLogs.length > 500) {
        updatedLogs.shift();
      }
      return {
        ...state,
        logs: updatedLogs,
      };
    }),
    on(settingsActions.resetLogsFromStorage, (state) => ({
      ...state,
      logs: [],
    }))
  ),
  extraSelectors: ({ selectSettings }) => ({
    selectLanguage: createSelector(selectSettings, (selectSettings) => (selectSettings ? selectSettings.language : "de")),
    selectCollapseTeamCalendar: createSelector(selectSettings, (selectSettings) =>
      selectSettings ? selectSettings.collapseTeamCalendar : false
    ),
    selectUseCustomTime: createSelector(selectSettings, (selectSettings) => (selectSettings ? selectSettings.useCustomTime : false)),
  }),
});

export const {
  name: settingsFeatureKey,
  reducer: settingsReducer,
  selectSettings,
  selectLogs,
  selectLanguage,
  selectCollapseTeamCalendar,
  selectUseCustomTime,
} = settingsFeature;
