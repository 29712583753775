import { error } from "console";
import { EMessageType } from "../../enums/message-type.enum";
import * as UIActions from "./ui.actions";
import { HttpErrorResponse } from "@angular/common/http";

export interface UIState {
    message?: string,
    type?: EMessageType,
    supportFile: boolean,
    internal: boolean,
    loading: boolean,
    showSplash: boolean
}

const initialState: UIState = {
    message: '',
    type: undefined,
    supportFile: false,
    internal: false,
    loading: false,
    showSplash: true
}

export function uiReducer(state: UIState = initialState, action: UIActions.UIActions): UIState {

    switch(action.type) {
        case UIActions.MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                type: action.payload.type,
                supportFile: false,
                internal: false,
                loading: false
            }
        case UIActions.ERROR_MESSAGE:
            return {
                ...state,
                message: action.payload.message,
                type: EMessageType.ERROR,
                supportFile: action.payload.supportFile,
                internal: action.payload.internal,
                loading: false
            }
        case UIActions.START_LOADING:
            return {
                ...state,
                message: '',
                type: undefined,
                supportFile: false,
                internal: false,
                loading: true
            }
        case UIActions.STOP_LOADING:
            return {
                ...state,
                message: '',
                type: undefined,
                supportFile: false,
                internal: false,
                loading: false
            }
        case UIActions.RESET_MESSAGE:
            return {
                ...state,
                message: '',
                type: undefined,
                supportFile: false,
                internal: false,
                loading: false
            }
        case UIActions.SHOW_SPLASH:
            return {
                ...state,
                showSplash: true
            }
        case UIActions.HIDE_SPLASH:
            return {
                ...state,
                showSplash: false
            }
        default:
            return state;
    }
}