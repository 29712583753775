import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { Accounts } from "../../interfaces/account.types";
import { Store } from "@ngrx/store";
import { IAppState } from "../app/app.reducer";
import { Employee } from "../../models/employee.model";
import { IWorkTimeScheme } from "../../interfaces/work-time-scheme.interface";
import { environment } from "src/environments/environment";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { format } from "date-fns";
import { NavController } from "@ionic/angular";
import { Parent } from "../../models/parent.model";
import { utcToZonedTime } from "date-fns-tz";
import { UsersState } from "./users.reducer";
import { EMessageType } from "../../enums/message-type.enum";
import { SharedService } from "src/app/shared/services/shared.service";
import * as UsersActions from "./users.actions";
import * as UIActions from "../ui/ui.actions";
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class UsersEffects {
  fetchAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.FETCH_ALL),
      tap((action: UsersActions.FetchAll) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info("Fetching users from server");
      }),
      switchMap((action: UsersActions.FetchAll) => {
        return this.http.get(environment.apiUrl + "users", { observe: "response" }).pipe(
          map((response: HttpResponse<any>) => {
            const users: Accounts[] = [];
            const dataArray: any[] = response.body.data;
            dataArray.forEach((data, index) => {
              const user: Accounts = this.sharedService.createUserFromAPI(data);
              users.push(user);
            });
            users.sort((a, b) => {
              return a.lastName.localeCompare(b.lastName);
            });
            return new UsersActions.SetUsers(users);
          }),
          catchError((errorRes) => {
            this.navController.navigateBack(["app", "startseite"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  fetchEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.FETCH_EMPLOYEES),
      tap((action: UsersActions.FetchEmployees) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info("Fetching users from server");
      }),
      switchMap((action: UsersActions.FetchEmployees) => {
        return this.http.get(environment.apiUrl + "employees", { observe: "response" }).pipe(
          map((response: HttpResponse<any>) => {
            const users: Accounts[] = [];
            const dataArray: any[] = response.body.data;
            dataArray.forEach((data, index) => {
              const user: Accounts = this.sharedService.createUserFromAPI(data);
              users.push(user);
            });
            users.sort((a, b) => {
              return a.lastName.localeCompare(b.lastName);
            });
            return new UsersActions.SetEmployees(users);
          }),
          catchError((errorRes) => {
            this.navController.navigateBack(["app", "startseite"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.FETCH_USER),
      tap((action: UsersActions.FetchUser) => {
        this.store.dispatch(new UIActions.StartLoading());
      }),
      switchMap((action: UsersActions.FetchUser) => {
        let url = this.getUserApiUrl(action.payload);

        return this.http.get(environment.apiUrl + url, { observe: "response" }).pipe(
          map((response: HttpResponse<any>) => {
            const users: Accounts[] = [];
            const user: Accounts = this.sharedService.createUserFromAPI(response.body.data);
            return new UsersActions.SetUser(user);
          }),
          catchError((errorRes) => {
            this.navController.navigateBack(["app", "startseite"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  setUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SET_USERS),
      map((action: UsersActions.SetUsers) => {
        this.logger.info("Set users in store");
        return new UIActions.StopLoading();
      }),
      catchError((errorRes) => {
        return of(new UIActions.Error({ error: errorRes, internal: false }));
      })
    )
  );

  setEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SET_EMPLOYEES),
      map((action: UsersActions.SetEmployees) => {
        this.logger.info("Set employees in store");
        return new UIActions.StopLoading();
      }),
      catchError((errorRes) => {
        return of(new UIActions.Error({ error: errorRes, internal: false }));
      })
    )
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.SET_USER),
      withLatestFrom(this.store.select("users")),
      switchMap(([action, usersState]: [UsersActions.SetUser, UsersState]) => {
        const path = "app/mitglieder" + usersState.path + "/" + action.payload.userId;
        if (usersState.backNavigation) {
          this.navController.navigateBack([path], { replaceUrl: true });
        } else {
          this.navController.navigateForward([path]);
        }
        if (usersState.backNavigation) {
          return of(
            new UIActions.Message({
              message: this.translateService.instant("CORE.MESSAGES.USER_DATA_UPDATED"),
              type: EMessageType.INFORMATION,
            })
          );
        } else {
          return of(new UIActions.StopLoading());
        }
      }),
      catchError((errorRes) => {
        return of(new UIActions.Error({ error: errorRes, internal: false }));
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UPDATE_USER),
      tap((action: UsersActions.UpdateUser) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Updating data of user ${action.payload.updatedUser.userId}`);
      }),
      switchMap((action: UsersActions.UpdateUser) => {
        const user = action.payload.updatedUser;
        return this.http
          .patch<any>(
            environment.apiUrl + "users/" + user.userId,
            {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              role: user.role,
              phone: user.phone,
              address: user.address,
              houseNr: user.houseNr,
              zipCode: user.zipCode,
              city: user.city,
              entryDate: user.entryDate ? format(utcToZonedTime(user.entryDate, "UTC"), "yyyy-MM-dd HH:mm:ss") : null,
              exitDate: user.exitDate ? format(utcToZonedTime(user.exitDate, "UTC"), "yyyy-MM-dd HH:mm:ss") : null,
              status: user.status,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              return new UsersActions.FetchUser(action.payload.updatedUser);
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.ADD_USER),
      tap((action: UsersActions.AddUser) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Adding new user`);
      }),
      switchMap((action: UsersActions.AddUser) => {
        const user = action.payload;
        return this.http
          .post<any>(
            environment.apiUrl + "users",
            {
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              gender: user.gender,
              role: user.role,
              phone: user.phone,
              address: user.address,
              houseNr: user.houseNr,
              zipCode: user.zipCode,
              city: user.city,
              entryDate: user.entryDate ? format(utcToZonedTime(user.entryDate, "UTC"), "yyyy-MM-dd HH:mm:ss") : null,
              exitDate: user.exitDate ? format(utcToZonedTime(user.exitDate, "UTC"), "yyyy-MM-dd HH:mm:ss") : null,
              status: user.status,
            },
            { observe: "response" }
          )
          .pipe(
            map((response: HttpResponse<any>) => {
              if (action.payload instanceof Employee) {
                const workTimeScheme = { ...action.payload.workTimeSchemes[0] };
                workTimeScheme.uuid = response.body.data.id.toString();
                return new UsersActions.AddWorkTimeScheme({ item: workTimeScheme, navigate: true });
              } else {
                this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
                return new UIActions.StopLoading();
              }
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  addWorkTimeScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.ADD_WORKTIMESCHEME),
      switchMap((action: UsersActions.AddWorkTimeScheme) => {
        this.logger.info(`Adding work time scheme for user ${action.payload.item.uuid}`);
        const workTimeScheme: IWorkTimeScheme = action.payload.item;
        return this.http
          .post<any>(
            environment.apiUrl + "worktimeschemes",
            {
              employee_id: +workTimeScheme.uuid,
              start_date: format(utcToZonedTime(workTimeScheme.startDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              end_date: workTimeScheme.endDate ? format(utcToZonedTime(workTimeScheme.endDate, "UTC"), "yyyy-MM-dd HH:mm:ss") : null,
              start_time: workTimeScheme.startTime,
              end_time: workTimeScheme.endTime,
              working_hours: workTimeScheme.workingHours,
              vacation_days: workTimeScheme.vacationDays,
              monday: workTimeScheme.workingDays.monday,
              tuesday: workTimeScheme.workingDays.tuesday,
              wednesday: workTimeScheme.workingDays.wednesday,
              thursday: workTimeScheme.workingDays.thursday,
              friday: workTimeScheme.workingDays.friday,
              carryover_vacations: workTimeScheme.carryover_vacations,
              carryover_overtime: workTimeScheme.carryover_overtime,
            },
            { observe: "response" }
          )
          .pipe(
            map((response: HttpResponse<any>) => {
              if (action.payload.navigate) {
                this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
              }
              return new UIActions.StopLoading();
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  updateWorkTimeScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.UPDATE_WORKTIMESCHEME),
      switchMap((action: UsersActions.UpdateWorkTimeScheme) => {
        this.logger.info(`Updating work time scheme with id ${action.payload.id} of user ${action.payload.uuid}`);
        const workTimeScheme: IWorkTimeScheme = action.payload;
        return this.http
          .patch<any>(
            environment.apiUrl + "worktimeschemes/" + workTimeScheme.id,
            {
              id: +workTimeScheme.id,
              employee_id: +workTimeScheme.uuid,
              start_date: format(utcToZonedTime(workTimeScheme.startDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              end_date: workTimeScheme.endDate ? format(utcToZonedTime(workTimeScheme.endDate, "UTC"), "yyyy-MM-dd HH:mm:ss") : null,
              working_hours: workTimeScheme.workingHours,
              vacation_days: workTimeScheme.vacationDays,
              monday: workTimeScheme.workingDays.monday,
              tuesday: workTimeScheme.workingDays.tuesday,
              wednesday: workTimeScheme.workingDays.wednesday,
              thursday: workTimeScheme.workingDays.thursday,
              friday: workTimeScheme.workingDays.friday,
              carryover_vacations: workTimeScheme.carryover_vacations,
              carryover_overtime: workTimeScheme.carryover_overtime,
            },
            { observe: "response" }
          )
          .pipe(
            map((response: HttpResponse<any>) => {
              return new UIActions.StopLoading();
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.DELETE_USER),
      tap((action: UsersActions.DeleteUser) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info(`Deleting user with id ${action.payload}`);
      }),
      switchMap((action: UsersActions.DeleteUser) => {
        const id = action.payload;
        return this.http.delete<any>(environment.apiUrl + "users/" + id, { observe: "response" }).pipe(
          map(() => {
            this.logger.info(`User with id ${action.payload} deleted.`);
            this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
            return new UIActions.StopLoading();
          }),
          catchError((errorRes) => {
            this.navController.navigateBack(["app", "mitglieder"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private http: HttpClient,
    private navController: NavController,
    private sharedService: SharedService,
    private logger: NGXLogger,
    private translateService: TranslateService
  ) {}

  private getUserApiUrl(user: Accounts): string {
    if (user instanceof Employee) {
      return `employees/${user.userId}`;
    } else if (user instanceof Parent) {
      return `parents/${user.userId}`;
    } else {
      return `users/${user.userId}`;
    }
  }
}
