<ion-card>
  <ion-card-content>
    <div *ngIf="canStartWorkRecords">
      <ion-grid (click)="openWorkRecordsOverview()">
        <ion-row>
          <ion-col>
            <app-hours-progress title="{{ 'PAGES.ACCOUNT.TODAY' | translate }}" isHours="{{ isHoursToday }}" shouldHours="{{ shouldHoursToday }}"></app-hours-progress>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <app-hours-progress title="{{ 'PAGES.ABSENT_RECORD.WEEK' | translate }}" isHours="{{ isHoursWeek }}" shouldHours="{{ shouldHoursWeek }}"></app-hours-progress>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <app-hours-progress title="{{ 'PAGES.ABSENT_RECORD.MONTH' | translate }}" isHours="{{ isHoursMonths }}" shouldHours=" {{ shouldHoursMonths }}"></app-hours-progress>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row>
          <ion-col class="flex">
            <div *ngIf="!this.alias" class="btn-wrapper" (click)="startedWorkRecord ? stopWorkRecord() : startWorkRecord()">
              <ion-button [disabled]="disableButtonStart" fill="clear" shape="round">
                <fa-icon [class]="startedWorkRecord ? 'stop' : ''" [icon]="startedWorkRecord ? 'circle-stop' : 'circle-play'"></fa-icon>
              </ion-button>
              <span class="ion-activatable ripple-parent rectangle ion-text-nowrap">
                <ion-ripple-effect></ion-ripple-effect>
                {{ startedWorkRecord ? startTime : ("PAGES.ABSENT_RECORD.CLOCK_IN" | translate) }}
              </span>
            </div>
            <div class="btn-wrapper" (click)="openWorkRecords()">
              <ion-button [disabled]="disableButtonNew" fill="clear" shape="round">
                <fa-icon icon="circle-plus"></fa-icon>
              </ion-button>
              <span class="ion-activatable ripple-parent rectangle ion-text-nowrap">
                <ion-ripple-effect></ion-ripple-effect>
                {{ "PAGES.ABSENT_RECORD.WORKING_TIME" | translate }}
              </span>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ion-item *ngIf="!canStartWorkRecords" lines="none" class="ion-text-center">
      <ion-label color="medium" class="ion-text-wrap">{{
        "COMPONENTS.HOURS_OVERVIEW.START_PENDING" | translate:{date: workRecordStartDate}
        }}
      </ion-label>
    </ion-item>
  </ion-card-content>
</ion-card>