import { Action } from "@ngrx/store";
import { Absence } from "../../models/absence.model";

export const FETCH = '[ABSENCES] Fetch data';
export const FETCHOPEN = ' [ABSENCES] Fetch Open absences';
export const SET_ABSENCES = '[ABSENCES] Set abences';
export const ADD_ABSENCE = '[ABSENCES] Add absence';
export const UPDATE_ABSENCE = '[ABSENCES] Update absence';
export const UPDATE_ABSENCE_STATUS = '[ABSENCES] Update absence status';
export const DELETE_ABSENCE = '[ABSENCES] Delete absence';

export class Fetch implements Action {
    readonly type = FETCH;
    constructor(public payload: { id: string, navigate: boolean }) { }
}

export class FetchOpen implements Action {
    readonly type = FETCHOPEN;
    constructor(public payload: boolean) { }
}
export class SetAbsences implements Action {
    readonly type = SET_ABSENCES;
    constructor(public payload: Absence[]) { }
}

export class AddAbsence implements Action {
    readonly type = ADD_ABSENCE;
    constructor(public payload: { absence: Absence, navigate: boolean }) { }
}

export class UpdateAbsence implements Action {
    readonly type = UPDATE_ABSENCE;
    constructor(public payload: { absence: Absence, navigate: boolean }) { }
}

export class UpdateAbsenceStatus implements Action {
    readonly type = UPDATE_ABSENCE_STATUS;
    constructor(public payload: { absence: Absence, navigate: boolean }) { }
}

export class DeleteAbsence implements Action {
    readonly type = DELETE_ABSENCE;
    constructor(public payload: { absence: Absence, navigate: boolean }) { }
}

export type AbsenceActions = Fetch | FetchOpen | SetAbsences | AddAbsence | UpdateAbsence | UpdateAbsenceStatus | DeleteAbsence;