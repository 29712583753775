import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, Observable, take } from "rxjs";
import { EUserRole } from "src/app/core/enums/role.enum";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { AuthState } from "src/app/core/store/auth/auth.reducer";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private store: Store<IAppState>, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        return this.store.select('account').pipe(
            map((authState: AuthState) => {
            if (!authState.account && !authState.authData) {
                return this.router.createUrlTree(['/auth']);
            }
            return true;
        }))

    }
}