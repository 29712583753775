import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ISettings } from "./interfaces/settings.interfaces";
import { INGXLoggerMetadata } from "ngx-logger";

/**
 * Actions for managing settings in the application.
 */
export const settingsActions = createActionGroup({
  source: "Settings",
  events: {
    /**
     * Action to fetch settings from the server.
     */
    "Fetch Settings": emptyProps,

    /**
     * Action dispatched when fetching settings from the server is successful.
     * @param settings - The fetched settings.
     */
    "Fetch settings success": props<{ settings: ISettings }>(),

    /**
     * Action dispatched when fetching settings from the server fails.
     */
    "Fetch settings failure": emptyProps,

    /**
     * Action to set the language in the application.
     * @param language - The language to set.
     */
    "Set language": props<{ language: string }>(),

    /**
     * Action to toggle the team calendar in the application.
     * @param collapseTeamCalendar - Whether to collapse the team calendar or not.
     */
    "Toggle Team calendar": props<{ collapseTeamCalendar: boolean }>(),

    /**
     * Action to fetch logs from the server.
     */
    "Fetch logs": emptyProps,

    /**
     * Action dispatched when fetching logs from the server is successful.
     * @param logs - The fetched logs.
     */
    "Fetch logs success": props<{ logs: INGXLoggerMetadata[] }>(),

    /**
     * Action to save logs in the local store.
     */
    "Save logs in locale store": emptyProps,

    /**
     * Action to save settings in the local store.
     */
    "Save settings in locale store": emptyProps,

    /**
     * Action to add a new log message.
     * @param log - The log message to add.
     */
    "Add new log message": props<{ log: INGXLoggerMetadata }>(),

    /**
     * Action to send logs to the server.
     * @param message - The message to send.
     */
    "Send logs to server": props<{ message: string }>(),

    /**
     * Action to reset logs from the storage.
     */
    "Reset logs from storage": emptyProps,

    /**
     * Action to use custom time in the application.
     * @param useCustomTime - Whether to use custom time or not.
     */
    "Use custom time": props<{ useCustomTime: boolean }>(),
  },
});
