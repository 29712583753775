import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { Absence } from "../../models/absence.model";
import { SharedService } from "src/app/shared/services/shared.service";
import { Location } from "@angular/common";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { IAppState } from "../app/app.reducer";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import * as UIActions from "src/app/core/store/ui/ui.actions";
import * as AbsencesActions from "src/app/core/store/absences/absences.actions";
import { NGXLogger } from "ngx-logger";
import { uiActions } from "../ui/actions";

@Injectable()
export class AbsencesEffects {
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.FETCH),
      tap(() => {
        this.store.dispatch(uiActions.fetchAbsences());
      }),
      switchMap((action: AbsencesActions.Fetch) => {
        this.logger.info("Fetching absences from server");
        return this.http.get<any>(environment.apiUrl + "employees/" + action.payload.id + "/absences").pipe(
          map((response: any) => {
            const ABSENCES: Absence[] = [];
            response.data.forEach((absence: any, index: number) => {
              var a: Absence = this.sharedService.createAbsenceFromResponse(absence);
              ABSENCES.push(a);
            });
            if (action.payload.navigate) {
              this.location.back();
            }
            return new AbsencesActions.SetAbsences(ABSENCES);
          }),
          catchError((errorRes) => {
            this.store.dispatch(uiActions.failedFetching());
            this.navController.navigateBack(["app", "startseite"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  fetchOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.FETCHOPEN),
      tap(() => {
        this.store.dispatch(uiActions.fetchOpenAbsences());
      }),
      switchMap((action: AbsencesActions.Fetch) => {
        this.logger.info("Fetching open absences from server");
        return this.http.get<any>(environment.apiUrl + "absences/open").pipe(
          map((response: any) => {
            const ABSENCES: Absence[] = [];
            response.data.forEach((absence: any, index: number) => {
              var a: Absence = this.sharedService.createAbsenceFromResponse(absence);
              ABSENCES.push(a);
            });
            if (action.payload) {
              this.location.back();
            }
            return new AbsencesActions.SetAbsences(ABSENCES);
          }),
          catchError((errorRes) => {
            this.store.dispatch(uiActions.failedFetching());
            this.navController.navigateBack(["app", "startseite"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.ADD_ABSENCE),
      tap((action: AbsencesActions.AddAbsence) => {
        this.store.dispatch(new UIActions.StartLoading());
        this.logger.info("Adding new absence");
      }),
      switchMap((action: AbsencesActions.AddAbsence) => {
        const absence: Absence = action.payload.absence;
        return this.http
          .post<any>(
            environment.apiUrl + "absences",
            {
              employee_id: absence.uuid,
              editor_id: absence.editorId,
              start_date: format(utcToZonedTime(absence.startDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              end_date: format(utcToZonedTime(absence.endDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              comment: absence.comment,
              comment_editor: absence.commentEditor,
              type: absence.type,
              notified: absence.notified,
              status: absence.status,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              return new AbsencesActions.Fetch({ id: absence.uuid, navigate: action.payload.navigate });
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "zeitkonto", "abwesenheiten"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.UPDATE_ABSENCE),
      tap((action: AbsencesActions.UpdateAbsence) => {
        this.store.dispatch(new UIActions.StartLoading());
      }),
      switchMap((action: AbsencesActions.UpdateAbsence) => {
        const absence: Absence = action.payload.absence;
        this.logger.info(`Updating absence with id: ${absence.id}`);
        return this.http
          .patch<any>(
            environment.apiUrl + "absences/" + absence.id,
            {
              employee_id: absence.uuid,
              editor_id: absence.editorId,
              start_date: format(utcToZonedTime(absence.startDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              end_date: format(utcToZonedTime(absence.endDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              comment: absence.comment,
              comment_editor: absence.commentEditor,
              type: absence.type,
              notified: absence.notified,
              status: absence.status,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              return new AbsencesActions.Fetch({ id: absence.uuid, navigate: action.payload.navigate });
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "zeitkonto", "abwesenheiten"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.UPDATE_ABSENCE_STATUS),
      tap((action: AbsencesActions.UpdateAbsenceStatus) => {
        this.store.dispatch(new UIActions.StartLoading());
      }),
      switchMap((action: AbsencesActions.UpdateAbsenceStatus) => {
        const absence: Absence = action.payload.absence;
        this.logger.info(`Updating status of absence with id: ${absence.id}`);
        return this.http
          .patch<any>(
            environment.apiUrl + "absences/" + absence.id,
            {
              employee_id: absence.uuid,
              editor_id: absence.editorId,
              start_date: format(utcToZonedTime(absence.startDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              end_date: format(utcToZonedTime(absence.endDate, "UTC"), "yyyy-MM-dd HH:mm:ss"),
              comment: absence.comment,
              comment_editor: absence.commentEditor,
              type: absence.type,
              notified: absence.notified,
              status: absence.status,
            },
            { observe: "response" }
          )
          .pipe(
            map(() => {
              return new AbsencesActions.FetchOpen(action.payload.navigate);
            }),
            catchError((errorRes) => {
              this.navController.navigateBack(["app", "startseite"], { replaceUrl: true });
              return of(new UIActions.Error({ error: errorRes, internal: false }));
            })
          );
      })
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.DELETE_ABSENCE),
      tap((action: AbsencesActions.DeleteAbsence) => {
        this.store.dispatch(new UIActions.StartLoading());
      }),
      switchMap((action: AbsencesActions.DeleteAbsence) => {
        const absence: Absence = action.payload.absence;
        this.logger.info(`Deleting absence with id: ${absence.id}`);
        return this.http.delete<any>(environment.apiUrl + "absences/" + absence.id, { observe: "response" }).pipe(
          map(() => {
            return new AbsencesActions.Fetch({ id: absence.uuid, navigate: action.payload.navigate });
          }),
          catchError((errorRes) => {
            this.navController.navigateBack(["app", "zeitkonto", "abwesenheiten"], { replaceUrl: true });
            return of(new UIActions.Error({ error: errorRes, internal: false }));
          })
        );
      })
    )
  );

  set$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbsencesActions.SET_ABSENCES),
      map(() => {
        this.store.dispatch(uiActions.successFetchingAbsences());
        this.store.dispatch(uiActions.successFetchingOpenAbsences());
        this.logger.info("Initialized absences in store");
        return new UIActions.StopLoading();
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private sharedService: SharedService,
    private location: Location,
    private navController: NavController,
    private store: Store<IAppState>,
    private logger: NGXLogger
  ) {}
}
