import { createFeature, createReducer, on } from "@ngrx/store";
import { IUsersState } from "./interfaces/users.interfaces";
import { usersActions } from "./actions";

const initialState: IUsersState = {
  employees: {
    all: undefined,
    online: null,
    offline: null,
    overdue: null,
    vacant: null,
    onAbsence: null,
    loading: false,
    sync: null,
  },
};

const usersFeature = createFeature({
  name: "usersFeature",
  reducer: createReducer(
    initialState,
    on(usersActions.fetchEmployees, (state) => ({
      ...state,
      employees: { ...state.employees, all: [], loading: true },
    })),
    on(usersActions.fetchEmployeesSuccess, (state, action) => ({
      ...state,
      employees: { ...state.employees, all: action.employees, loading: false },
    })),
    on(usersActions.setEmployeesSyncTime, (state, action) => ({
      ...state,
      employees: {
        ...state.employees,
        sync: action.payload,
      },
    })),
    on(usersActions.setEmployeesStates, (state, action) => ({
      ...state,
      employees: {
        ...state.employees,
        online: action.payload?.online,
        offline: action.payload?.offline,
        overdue: action.payload?.overdue,
        vacant: action.payload?.vacant,
        onAbsence: action.payload?.absence,
      },
    })),
    on(usersActions.fetchEmployeesFailure, (state) => ({
      ...state,
      employees: { ...state.employees, all: null, loading: false },
    }))
  ),
});

export const { name: usersFeatureKey, reducer: usersReducer, selectEmployees } = usersFeature;
