import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { utcToZonedTime } from "date-fns-tz";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { selectSystemTime } from "../reducer";
import { ITimeState } from "../interfaces/time.interface";

@Injectable({
  providedIn: "root",
})
export class TimeService {
  private _systemTime: Date | undefined = undefined;
  private endpoint = "server-info";
  constructor(private http: HttpClient, private store: Store) {
    this.store.select(selectSystemTime).subscribe((time: Date) => {
      this._systemTime = time;
    });
  }

  fetchSystemTime(): Observable<ITimeState> {
    const url = environment.apiUrl + this.endpoint;
    return this.http.get<any>(url, { observe: "response" }).pipe(
      map((response: HttpResponse<any>) => {
        const date = new Date(response.body.data.systemDate * 1000);
        const timezoneDate = utcToZonedTime(date, "Europe/Berlin");
        return { systemTime: timezoneDate, customTimeDiff: response.body.data.customDateDiff };
      })
    );
  }

  updateServerCustomTimeOn(timeDiff: number): Observable<Date> {
    const url = environment.apiUrl + this.endpoint;
    return this.http.post<any>(url, { time_diff: timeDiff }, { observe: "response" }).pipe(
      map((response: any) => {
        const date = new Date(response.body.data.systemDate * 1000);
        const timezoneDate = utcToZonedTime(date, "Europe/Berlin");
        return timezoneDate;
      })
    );
  }

  getSystemTime(): Date {
    let systemTime = new Date();

    if (this._systemTime) {
      systemTime = new Date(this._systemTime);
    }
    return systemTime;
  }

  getCustomTimeDiff(customTime: number, serverTime: number): number {
    return customTime - serverTime;
  }
}
